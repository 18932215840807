var exports = {};
exports = {
  A: {
    A: {
      "1": "B",
      "2": "J D E F A 6B"
    },
    B: {
      "1": "L G M N O P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "129": "C K"
    },
    C: {
      "1": "NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "2": "7B qB",
      "260": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB",
      "804": "I r J D E F A B C K L 8B 9B"
    },
    D: {
      "1": "TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "260": "OB PB QB RB SB",
      "388": "3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB",
      "1412": "0 1 2 G M N O s t u v w x y z",
      "1956": "I r J D E F A B C K L"
    },
    E: {
      "1": "0B 1B 2B pB 3B JC KC",
      "129": "A B C K L G GC xB nB oB yB HC IC zB",
      "1412": "J D E F EC FC",
      "1956": "I r CC wB DC"
    },
    F: {
      "1": "GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "2": "F LC MC",
      "260": "BB CB DB EB FB",
      "388": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB",
      "1796": "NC OC",
      "1828": "B C nB 4B PC oB"
    },
    G: {
      "1": "0B 1B 2B pB 3B",
      "129": "WC XC YC ZC aC bC cC dC eC fC gC hC iC jC zB",
      "1412": "E SC TC UC VC",
      "1956": "wB QC 5B RC"
    },
    H: {
      "1828": "kC"
    },
    I: {
      "1": "H",
      "388": "pC qC",
      "1956": "qB I lC mC nC oC 5B"
    },
    J: {
      "1412": "A",
      "1924": "D"
    },
    K: {
      "1": "d",
      "2": "A",
      "1828": "B C nB 4B oB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "1": "B",
      "2": "A"
    },
    O: {
      "1": "rC"
    },
    P: {
      "1": "uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C",
      "260": "sC tC",
      "388": "I"
    },
    Q: {
      "1": "yB"
    },
    R: {
      "1": "5C"
    },
    S: {
      "260": "6C"
    }
  },
  B: 4,
  C: "CSS3 Border images"
};
export default exports;